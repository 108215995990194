/** @format */

import { combineReducers } from "redux";
import locationReducer from "./location";
import { articleReducer } from "@avinet/react-article-module";
import { userReducer } from "./user";
import { pageReducer, navReducer } from "@avinet/react-adaptive-cms";
import { mapHistoryReducer } from "./mapHistory";
import { filterReducer } from "./filterReducer";
import { activityReducer } from "./activities";
import digiThemeReducer from "./digiThemeReducer";
import mediaReducer from "./mediaReducer";
import { authReducer } from "@avinet/react-adaptive-auth";
import config from "../config/index";

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    location: locationReducer,
    articles: articleReducer,
    page: pageReducer,
    user: userReducer,
    mapHistory: mapHistoryReducer,
    filterReducer: filterReducer,
    newsReducer: digiThemeReducer("newsReducer", config.dtNews.uuid),
    tripPoints: digiThemeReducer("tripPoints", config.dtPoint.uuid),
    tripLines: digiThemeReducer("tripLines", config.dtLine.uuid),
    municipalities: digiThemeReducer(
      "municipalities",
      config.dtMunicipality.uuid
    ),
    trips: digiThemeReducer("trips", config.dtTrips.uuid),
    activityReducer: activityReducer,
    nav: navReducer,
    mediaTrips: mediaReducer("mediaTrips", config.dtTrips.uuid),
    mediaFriluft: mediaReducer("mediaFriluft", config.dtFriluft.uuid),
    auth: authReducer,
    vkpElementGeomReducer: digiThemeReducer(
      "vkpElementGeomReducer",
      config.vestkystparkElementerPublic.uuid
    ),

    ...asyncReducers,
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
