import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { pageActions } from "@avinet/react-adaptive-cms";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ContactComponent from "../components/ContactComponent";
import { getPath, fixLinks } from "../utils/Path";
import Title from "../components/Title";
import LoadingIndicator from "../components/LoadingIndicator";
import BannerContent from "../components/FrontPage/BannerContent";
import AboutPreview from "../components/FrontPage/AboutPreview";
import OutportInfo from "../components/FrontPage/OutportInfo";
import BuoyInfo from "../components/FrontPage/BuoyInfo";
import "./PageView.scss";

const mapStateToProps = (state) => ({
  page: state.page.page,
  pageLoading: state.page.pageLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, pageActions), dispatch);

class PageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: "",
    };
  }
  componentDidMount() {
    const { loadPage, location } = this.props;
    const path = getPath(location);
    this.setState({ currentPath: path });
    loadPage(path);
  }

  componentWillReceiveProps(nextProps) {
    const path = getPath(nextProps.location);
    if (path !== this.state.currentPath) {
      this.setState({ currentPath: path });
      nextProps.loadPage(path);
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    fixLinks(history);
  }

  render() {
    const {
      page: { content, title },
      pageLoading,
    } = this.props;
    const { currentPath } = this.state;

    return (
      <div className="god-tur--page">
        <Title title={title} />
        {pageLoading ? (
          <div className="loader">
            <LoadingIndicator />
          </div>
        ) : (
          <div
            className="god-tur--cms"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        <div className="godtur--div-components">
          {currentPath === "index" && (
            <React.Fragment>
              <BannerContent />
              <div className="index-page--flex-wrapper">
                <AboutPreview />
                <BuoyInfo />
              </div>
              <OutportInfo />
            </React.Fragment>
          )}
          {currentPath === "kontakt" && <ContactComponent />}
        </div>
      </div>
    );
  }
}

PageView.propTypes = {
  page: PropTypes.object,
  pageLoading: PropTypes.bool,
  loadPage: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageView));
