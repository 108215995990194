import React, { useCallback } from "react";
import Icon from "./Icon";
import "./FeaturePopup.scss";
import config from "../config";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import ImageCarousel from "./ImageCarousel";
import useBadeTemp from "../hocs/useBadeTemp";
import bathTempSmall from "../static/bathTempSmall.svg";

function TripInfo({ properties, t }) {
  return (
    <div className="trip-info">
      <div className="meta-col">
        <span className="label">{t("length")}</span>
        <span className="value">
          {properties.lengde >= 1000
            ? `${(properties.lengde / 1000).toFixed(1)} km`
            : `${properties.lengde} m`}
        </span>
      </div>
      <div className="meta-col">
        <span className="label">{t("time")}</span>
        <span className="value">{properties.tid}</span>
      </div>
      <div className="meta-col">
        <span className="label">{t("season")}</span>
        <span className="value">{properties.sesong}</span>
      </div>
    </div>
  );
}

function FeaturePopup({
  selectedFeature,
  articles,
  history,
  onArticleClose,
  t,
  popupClass,
}) {
  const [properties, setProperties] = useState(null);
  const [imageurl, setImageurl] = useState(null);
  const closeRef = useRef();

  const shortentView = useMemo(() => {
    const id = selectedFeature.values_.id.split("_")[1];
    return id.startsWith("02") || id.startsWith("03");
  }, [selectedFeature]);

  const handleClose = useCallback(() => {
    onArticleClose();
  }, [onArticleClose]);

  const handleClick = useCallback(
    (e) => {
      if (!shortentView) return;
      if (
        e.target !== closeRef.current &&
        !closeRef.current.contains(e.target)
      ) {
        const baseUrl = "/tur";

        history.push(
          baseUrl + "/" + selectedFeature.values_.id.replace("_", "/")
        );
        window.scroll(0, 0);
      }
    },
    [shortentView, history, selectedFeature.values_.id]
  );

  useEffect(() => {
    if (!selectedFeature || !articles) return;

    const aid = selectedFeature.values_.id;

    const properties = articles[aid]?.properties;
    const imageid = properties?.bilde_multimedia;

    if (imageid) {
      const imageurl =
        config.adaptiveUrl +
        "WebServices/generic/Media.asmx/Download?uuid=" +
        imageid +
        "&thumbnail_size=small";

      setImageurl(imageurl);
    }
    setProperties(properties);

    return () => {
      setImageurl(null);
      setProperties(null);
    };
  }, [articles, selectedFeature]);

  const convertToClick = useCallback((e) => {
    const evt = new MouseEvent("click", { bubbles: true });
    evt.stopPropagation = () => {};
    e.target.dispatchEvent(evt);
  }, []);

  const record = useMemo(() => articles[selectedFeature.values_.id], [
    articles,
    selectedFeature.values_.id,
  ]);

  const { data } = useBadeTemp(properties?.badetemp_id || null);

  if (!properties) return null;

  return (
    <>
      <div className="mobile-overlay" />
      <div className={"feature-popup " + popupClass} onMouseUp={convertToClick}>
        <button className="close-btn" onClick={handleClose} ref={closeRef}>
          <Icon name="close" />
        </button>
        {record && <ImageCarousel record={record} small />}
        <div className={imageurl ? "category" : "category large"}>
          <div>{properties.type_friluftselement} </div>

          {data && (
            <div className="badetemp-container">
              <div className="temp-icon">
                <img alt="badetemp" src={bathTempSmall} />
              </div>
              {data?.[0].measValue + "˚C"}
            </div>
          )}
        </div>
        {properties.lengde && <TripInfo properties={properties} t={t} />}
        <div
          className={"popup-text-content" + (shortentView ? " selectable" : "")}
          onClick={handleClick}
        >
          <h4>{properties.title}</h4>
          {shortentView ? (
            <>
              <div
                className="shortent"
                dangerouslySetInnerHTML={{ __html: properties.content }}
              />
              <button onClick={handleClick} className="btn-link see-more">
                {t("seeMore")}
                <Icon name="chevronRight" />
              </button>
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: properties.content }} />
          )}
        </div>
      </div>
    </>
  );
}

export default FeaturePopup;
