import React from "react";
import { provideMapState } from "@avinet/react-openlayers";
import { PropTypes } from "prop-types";
import Icon from "./Icon";
import config from "../config/index";

class InitExtentButton extends React.Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.fitViewAndZoom(config.initExtent);
  }

  render() {
    const { className } = this.props;

    return (
      <div
        className={`init-extent-button ${className || ""}`}
        onClick={this.onClick}
        title="Zoom til hele landet"
      >
        <Icon name={"earth"} />
      </div>
    );
  }
}

InitExtentButton.propTypes = {
  fitViewAndZoom: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default provideMapState(InitExtentButton);
