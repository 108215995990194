import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import createStore from "./redux/createStore";
import { unregister } from "./registerServiceWorker";
import { CmsConfig } from "@avinet/react-adaptive-cms";
import flickr from "./utils/flickr";
import config from "./config/index";
import "./index.scss";

const initialState = window.__INITIAL_STATE__;
const store = createStore(initialState);

CmsConfig.init(config.adaptiveUrl, config.cms_site_id);
flickr(config.flickrConfig.flickrApiKey, config.flickrConfig.userId);
ReactDOM.render(<App store={store} />, document.getElementById("root"));
unregister();
