import React, { useMemo } from "react";
import bathTemp from "../static/bathTemp.svg";
import badetassen from "../static/badetassen.svg";
import useBadeTemp from "../hocs/useBadeTemp";

const BadeTemp = ({ id }) => {
  const { data } = useBadeTemp(id);

  const date = useMemo(() => {
    if (!data) return null;

    // hide if older than 7 days
    if (
      new Date(data[0].updatedAt) <
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    ) {
      return null;
    }

    let dateString = new Date(data[0].updatedAt).toLocaleDateString("nb-NO", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    if (dateString.at(0) === "0") {
      dateString = dateString.slice(1);
    }

    const date = dateString;

    return date;
  }, [data]);

  if (!data || !date) return null;

  return (
    <div className="badetemp-container">
      <div className="temp-icon">
        <img alt="badetemp" src={bathTemp} />
      </div>
      <div className="temp-info">
        <span className="celcius">
          {`${data?.[0].measValue}˚`}
          <span>c</span>
        </span>
        <span className="date">{date}</span>
        <a
          href="https://badetassen.no/"
          className="badetassen"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="badetassen" src={badetassen} />
        </a>
      </div>
    </div>
  );
};

export default BadeTemp;
