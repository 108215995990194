import React from "react";
import PropTypes from "prop-types";
import Title from "../components/Title";
import GodTurArticle from "../components/Article/GodTurArticle";
import FriluftselementArticle from "../components/Article/FriluftselementArticle";
import Icon from "../components/Icon";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import { ArticleWindow } from "@avinet/react-article-module";
import { connect } from "react-redux";
import "./ArticleDetailView.scss";

const mapStateToProps = (state) => ({
  articles: state.articles.articles,
});

class ArticleDetailView extends React.Component {
  constructor() {
    super();
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { history } = this.props;
    history.push("/alleturer/vestkystparkelementer"); // usikker
  }

  render() {
    const {
      t,
      match: {
        params: { sourceId, articleId },
      },
      articles,
    } = this.props;

    const aid = sourceId + "_" + articleId;
    const godTurId = articles[aid] && articles[aid].properties.godtur_id;

    return (
      <div className="article-detail-view--container" id="scrollPane">
        <Title title={t("title")} />
        {godTurId ? (
          <ArticleWindow
            articleComponent={GodTurArticle}
            id={aid}
            hideOpenExternalBtn
            hideLayers
            disableZoom
          >
            <div className="article-detail-view--children">
              <button
                className="article-detail-view--close-btn btn"
                onClick={this.onClose}
              >
                <Icon name="cross" />
              </button>
            </div>
          </ArticleWindow>
        ) : (
          <ArticleWindow
            articleComponent={FriluftselementArticle}
            id={aid}
            hideOpenExternalBtn
            hideLayers
            maxZoom={15}
          >
            <div className="article-detail-view--children">
              <button
                className="article-detail-view--close-btn btn"
                onClick={this.onClose}
              >
                <Icon name="cross" />
              </button>
            </div>
          </ArticleWindow>
        )}
      </div>
    );
  }
}

ArticleDetailView.propTypes = {
  articles: PropTypes.object,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  withRouter(translate("ArticleDetailView")(ArticleDetailView))
);
