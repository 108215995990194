/** @format */

import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators as scopedBindActionCreator } from "../utils/scopedReducer";
import { readFiltered } from "../redux/digiThemeReducer";
import { ensureArticleModuleConfig } from "@avinet/react-article-module";
import { connect } from "react-redux";
import {
  ClusterSource,
  clusterStackStyle,
  clusterText,
  olUtils,
  VectorSource,
  provideMapState,
} from "@avinet/react-openlayers";
import { Style, Fill, Stroke } from "ol/style";
import featureStyle from "../utils/featureStyle";
import config from "../config/index";

export const clusterStyle = clusterStackStyle({
  clusterLabelText: clusterText({ color: "#333" }),
  clusterLabelOffsetX: 10, // 0 is on top of each other.
  clusterLabelOffsetY: 20, // 0 is on top of each other.
  typeField: "type",
  featureStyle,
});

const mapDispatchToProps = (dispatch) => {
  return {
    readAllVkpElements: scopedBindActionCreator(
      readFiltered,
      dispatch,
      "vkpElementGeomReducer"
    ),
  };
};
const mapStateToProps = (state) => ({
  vkpElementGeoms: state.vkpElementGeomReducer.records,
  vkpElementGeomsLoading: state.vkpElementGeomReducer.loading,
});

class ArticleLayers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      vkpElementFeatures: [],
      vkpElementFeaturesPolygon: [],
      vkpElementFeaturesPolygonPoint: [],
    };
  }
  componentDidMount() {
    this.readVkpElements();
  }
  componentDidUpdate(prevProps) {
    const { filterColumns } = this.props;

    if (
      JSON.stringify(filterColumns.vestkystparkelementer) !==
      JSON.stringify(prevProps.filterColumns.vestkystparkelementer)
    ) {
      this.readVkpElements();
    }
  }
  polygonStyle = (f, resolution) => {
    const zIndex = 9999;

    return new Style({
      fill: new Fill({
        color: "rgba(65, 90, 149, 0.6)",
      }),
      stroke: new Stroke({
        color: "rgba(65, 90, 149, 0.8)",
      }),
      zIndex,
    });
    //}
  };

  readVkpElements() {
    const {
      readAllVkpElements,
      filterColumns,
      vkpElementGeomsLoading,
    } = this.props;
    const me = this;

    if (vkpElementGeomsLoading) {
      setTimeout(() => me.readVkpElements(), 50);
      return;
    }

    try {
      readAllVkpElements(
        config.vestkystparkElementerPublic.columns,
        {
          filterColumns: [...filterColumns.vestkystparkelementer],
        },
        config.mapProjCode,
        0,
        0
      );
      this.setState({
        shouldUpdateVkpElementFeatures: true,
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error("error: ", error);
      this.readVkpElements();
    }
  }

  getDatasource(dataIndex) {
    return this.props.sources.find((s) => s.dataIndex === dataIndex);
  }
  static getDerivedStateFromProps(props, state) {
    state.vestkystparkelementerIsActive = props.activeSources.some(
      (as) => as === config.vestkystparkElementerPublic.dataIndex
    );
    if (state.shouldUpdateVkpElementFeatures && !props.vkpElementGeomsLoading) {
      state.vkpElementFeatures = props.vkpElementGeoms
        .filter((e) => e.datakilde !== 2)
        .map((geom) => {
          const f = olUtils.createFeatureFromWkt(geom.geom_wkt, {
            dataIndex: config.vestkystparkElementerPublic.dataIndex,
            id: config.vestkystparkElementerPublic.dataIndex + "_" + geom.id,
            type: geom.type,
            symbol_id: geom.symbol_id,
            datakilde: geom.datakilde,
          });
          f.onClick = () => {
            props.onArticleOpen(f);
          };
          return f;
        });
      state.vkpElementFeaturesPolygon = props.vkpElementGeoms
        .filter((e) => e.datakilde === 2 && !!e.geom_omraade)
        .map((geom) => {
          const f = olUtils.createFeatureFromWkt(geom.geom_omraade, {
            dataIndex: config.vestkystparkElementerPublic.dataIndex,
            id: config.vestkystparkElementerPublic.dataIndex + "_" + geom.id,
            type: geom.type,
            symbol_id: geom.symbol_id,
            datakilde: geom.datakilde,
          });

          f.onClick = () => {
            props.onArticleOpen(f);
          };
          return f;
        });

      state.vkpElementFeaturesPolygonPoint = props.vkpElementGeoms
        .filter((e) => e.datakilde === 2)
        .map((geom) => {
          const f = olUtils.createFeatureFromWkt(geom.geom_wkt, {
            dataIndex: config.vestkystparkElementerPublic.dataIndex,
            id: config.vestkystparkElementerPublic.dataIndex + "_" + geom.id,
            type: geom.type,
            symbol_id: geom.symbol_id,
            datakilde: geom.datakilde,
          });

          f.onClick = () => {
            props.onArticleOpen(f);
          };
          return f;
        });
      state.shouldUpdateVkpElementFeatures = false;
    }
    return state;
  }
  render() {
    const {
      vkpElementFeatures,
      vkpElementFeaturesPolygon,
      vkpElementFeaturesPolygonPoint,
    } = this.state;
    const { mapZoom } = this.props;

    const vestkystparkElementSource = this.getDatasource(
      config.vestkystparkElementerPublic.dataIndex
    );

    return (
      <React.Fragment>
        <ClusterSource
          key={vestkystparkElementSource.dataIndex}
          layerName={vestkystparkElementSource.dataIndex}
          features={vkpElementFeatures}
          style={clusterStyle}
          zIndex={100}
          clusterThreshold={50}
        />
        {mapZoom >= 11.5 ? (
          <VectorSource
            key={"polygon_layer"}
            layerName={"polygon_layer"}
            features={vkpElementFeaturesPolygon}
            zIndex={-1}
            style={this.polygonStyle}
          />
        ) : (
          <ClusterSource
            key={"polygon_layer_point"}
            layerName={"polygon_layer_point"}
            features={vkpElementFeaturesPolygonPoint}
            style={clusterStyle}
            zIndex={100}
            clusterThreshold={50}
          />
        )}
      </React.Fragment>
    );
  }
}
ArticleLayers.propTypes = {
  sources: PropTypes.array.isRequired,
  // eslint-disable-next-line
  activeSources: PropTypes.array.isRequired,
  // eslint-disable-next-line
  onArticleOpen: PropTypes.func.isRequired,
  filterColumns: PropTypes.object,
  readAllVkpElements: PropTypes.func.isRequired,
  // eslint-disable-next-line
  vkpElementGeomsLoading: PropTypes.bool,
  // eslint-disable-next-line
  tripGeoms: PropTypes.array,
  // eslint-disable-next-line
  tripGeomsLoading: PropTypes.bool,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideMapState(ensureArticleModuleConfig(ArticleLayers)));
