import { useEffect, useState } from "react";
import request from "superagent";

function useBadeTemp(id) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    setData(undefined);

    if (id === undefined || id === null || id === "") {
      setLoading(false);
      return;
    }

    request
      .get(
        "https://api-badetassen.eu.ngrok.io/test/get/iot/bof/data?deviceID=" +
          id
      )
      .then((res) => res.body)
      .then((res) => {
        if (res && res[0].records) {
          // only use the latest record
          const data = res[0].records.slice(-1);
          setData(data);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  return { data, loading };
}

export default useBadeTemp;
