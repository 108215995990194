import { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { ensureMap, provideMapState } from "@avinet/react-openlayers";
import Overlay from "ol/Overlay";
import usePortal from "../hocs/usePortal";

const MapOverlay = ensureMap(
  ({
    id,
    coords,
    children,
    map,
    autoPan = false,
    selectedFeature,
    changePopupClass,
    fitViewAndZoom,
  }) => {
    const overlayRef = useRef();
    const portal = usePortal(id);

    useEffect(() => {
      const overlay = new Overlay({
        element: portal,
        autoPan,
        stopEvent: true,
        positioning: "top-center",
      });

      map.addOverlay(overlay);

      overlayRef.current = overlay;

      return () => map.removeOverlay(overlay);
    }, [map, portal, autoPan]);

    useEffect(() => {
      if (overlayRef.current) {
        overlayRef.current.setPosition(coords);
      }
    }, [overlayRef, coords]);

    useEffect(() => {
      const top = overlayRef.current.element.style.top.replace("px", "");
      const left = overlayRef.current.element.style.left.replace("px", "");

      const positioning = {
        x: "center",
        y: "",
      };

      const thresholdTop = Math.round((window.innerHeight / 2 + 39) * 0.5);
      const thresholdBottom = window.innerHeight * 0.65;
      const thresholdLeft = Math.round((window.innerWidth / 2) * 0.2);
      const thresholdRight = window.innerWidth - thresholdLeft;

      if (selectedFeature.getGeometry().getType() !== "Point") {
        fitViewAndZoom(selectedFeature.getGeometry().getExtent());
        positioning.y = "center";
        positioning.x = "left";
        changePopupClass("left");
      } else {
        if (top < thresholdTop) {
          positioning.y = "top";
          changePopupClass("top");
        } else if (top > thresholdBottom) {
          positioning.y = "bottom";
          changePopupClass("bottom");
        } else {
          positioning.y = "center";
          changePopupClass("");
        }

        if (left < thresholdLeft) {
          positioning.x = "left";
          changePopupClass("left");
        } else if (left > thresholdRight) {
          positioning.x = "right";
          changePopupClass("right");
        } else {
          if (positioning.y !== "center") {
            positioning.x = "center";
          } else {
            const screenXCenter = Math.round(window.innerWidth / 2);

            if (left > screenXCenter) {
              positioning.x = "right";
              changePopupClass("right");
            } else {
              positioning.x = "left";
              changePopupClass("left");
            }
          }
        }
      }

      overlayRef.current.setPositioning(positioning.y + "-" + positioning.x);

      return () => {
        overlayRef.current.setPositioning("top-center");
      };
    }, [selectedFeature, changePopupClass, fitViewAndZoom]);

    return createPortal(children, portal);
  }
);

export default provideMapState(MapOverlay);
