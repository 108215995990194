import request from "superagent";
import {
  getSessionToken,
  authReducerActions,
} from "@avinet/react-adaptive-auth";
import jsSHA from "jssha";
import { fixDate } from "../utils/date";
import config from "../config/index";

// ------------------------------------
// Constants
// ------------------------------------
export const READING_USER = "READING_USER";
export const USER_READ = "USER_READ";

export const REGISTERING_USER = "REGISTERING_USER";
export const USER_REGISTERED = "USER_REGISTERED";

export const UPDATING_USER = "UPDATING_USER";
export const USER_UPDATED = "USER_UPDATED";

export const DELETING_USER = "DELETING_USER";
export const USER_DELETED = "USER_DELETED";

// ------------------------------------
// Actions
// ------------------------------------

const readingUser = () => ({ type: READING_USER });

const userRead = (error, user) => ({
  type: USER_READ,
  payload: {
    error,
    user,
  },
});

const userRegistered = (error, email) => ({
  type: USER_REGISTERED,
  payload: {
    error,
    email,
  },
});

const userUpdated = (error) => ({
  type: USER_UPDATED,
  payload: {
    error,
  },
});

const updatingUser = () => ({
  type: UPDATING_USER,
});

const userDeleted = (error) => ({
  type: USER_DELETED,
  payload: {
    error,
  },
});

const deletingUser = () => ({
  type: DELETING_USER,
});

// ------------------------------------
// Action creators
// ------------------------------------
export function getUserDetails() {
  return (dispatch) => {
    var sessionId = getSessionToken();
    if (!sessionId) {
      dispatch(userRead({ message: "Not logged in. Cannot get user." }, null));
      return;
    }

    dispatch(readingUser());

    request
      .post(
        config.adaptiveUrl +
          "WebServices/generic/Authentication.asmx/GetUserDetails"
      )
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", sessionId)
      .set("gm_lang_code", config.gm_lang_code)
      .send()
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(
            userRead(err || { message: "Received an error from server" }, null)
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            userRead(
              response.exception || {
                message: "Received an error from server",
              },
              null
            )
          );
          return;
        }

        if (!response.data || !response.data.length) {
          dispatch(userRead({ message: "Empty response from server" }, null));
          return;
        }
        const user = response.data[0].value;
        if (user.create_date) {
          user.create_date = fixDate(user.create_date);
        }
        if (user.last_logged_in) {
          user.last_logged_in = fixDate(user.last_logged_in);
        }
        if (user.last_pass_change) {
          user.last_pass_change = fixDate(user.last_pass_change);
        }
        if (user.modify_date) {
          user.modify_date = fixDate(user.modify_date);
        }
        if (user.user_data.create_date) {
          user.user_data.create_date = fixDate(user.user_data.create_date);
        }
        if (user.user_data.modify_date) {
          user.user_data.modify_date = fixDate(user.user_data.modify_date);
        }
        dispatch(userRead(null, user));
      });
  };
}

export function clearUser() {
  return (dispatch) => {
    dispatch(userRead(null, null));
  };
}

export function registerUser(
  firstname,
  surname,
  email,
  pass,
  mobile,
  organization
) {
  return (dispatch) => {
    dispatch({ type: REGISTERING_USER, payload: true });

    // eslint-disable-next-line
    var shaObj = new jsSHA("SHA-512", "TEXT");
    shaObj.update(pass);
    var hash = shaObj.getHash("HEX");

    const params = {
      url: config.guiUrl,
      email: email,
      pass: hash,
      mobile: mobile,
      company: organization,
      first_name: firstname,
      last_name: surname,
    };

    request
      .post(
        config.adaptiveUrl +
          "WebServices/generic/Authentication.asmx/RegisterUser"
      )
      .send(params)
      .set("Accept", "application/json")
      .set("gm_lang_code", config.gm_lang_code)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(
            userRegistered({ message: "Error returned from service" }, null)
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            userRegistered(
              response.exception || {
                message: "Error returned from service",
              },
              null
            )
          );
          return;
        }
        dispatch(userRegistered(null, email));
      });
  };
}

export const updateUser = (user) => {
  return (dispatch) => {
    const sessionId = getSessionToken();
    if (!sessionId) {
      dispatch(
        userUpdated({ message: "Not logged in. Cannot get user." }, null)
      );
      return;
    }
    const data = {
      firstname: user.user_data.first_name,
      surname: user.user_data.last_name,
      mobile: user.user_data.mobile,
      organization: user.user_data.company,
      email: user.email,
    };
    dispatch(updatingUser());
    request
      .post(config.adaptiveUrl + "WebServices/godtur/User.asmx/UpdateUser")
      .send(data)
      .set("Accept", "application/json")
      .set("gm_lang_code", config.gm_lang_code)
      .set("gm_session_id", sessionId)
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(
            userUpdated(
              err || { message: "Received an error from server" },
              null
            )
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            userUpdated(
              response.exception || {
                message: "Received an error from server",
              },
              null
            )
          );
          return;
        }
        dispatch(userUpdated(null));
      });
  };
};

export function deleteUser(uuid, callback) {
  return (dispatch) => {
    var sessionId = getSessionToken();
    if (!sessionId) {
      dispatch(
        userDeleted({ message: "Not logged in. Cannot get user." }, null)
      );
      return;
    }

    dispatch(deletingUser());

    request
      .post(
        config.adaptiveUrl +
          "WebServices/telltur/Users.asmx/DeleteTellTurAccount"
      )
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("gm_session_id", sessionId)
      .set("gm_lang_code", config.gm_lang_code)
      .send({
        uuid,
      })
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch(
            userDeleted(
              err || { message: "Received an error from server" },
              null
            )
          );
          return;
        }
        response = response.body.d;

        if (response.sessionExpired) {
          dispatch(authReducerActions.sessionExpired());
        }

        if (response.exception || !response.success) {
          dispatch(
            userDeleted(
              response.exception || {
                message: "Received an error from server",
              },
              null
            )
          );
          return;
        }
        dispatch(userDeleted(null));
        if (callback) {
          callback();
        }
      });
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  error: null,
  user: null,
  registeredUserEmail: "",
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case READING_USER:
      return Object.assign({}, state, {
        loading: true,
        user: null,
        error: null,
      });
    case REGISTERING_USER:
      return Object.assign({}, state, {
        loading: true,
        user: null,
        error: null,
        registeredUserEmail: "",
      });
    case USER_READ:
      return Object.assign({}, state, {
        loading: false,
        user: action.payload.user,
        error: action.payload.error,
      });
    case USER_REGISTERED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error,
        user: null,
        registeredUserEmail: action.payload.email,
      });
    case USER_UPDATED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error,
      });
    case UPDATING_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case USER_DELETED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error,
      });
    case DELETING_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    default:
      return state;
  }
}
