import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "../lib/lazyload";
import GodTurArticleListComponent from "../components/Article/GodTurArticleListComponent";
import SlideMenu from "../containers/SlideMenu";
import { withRouter } from "react-router-dom";
import { provideViewSize } from "../hocs/provideViewSize";
import {
  Articles,
  ensureArticleModuleConfig,
} from "@avinet/react-article-module";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { provideMapState, MapPadding } from "@avinet/react-openlayers";
import ArticleLayers from "./ArticleLayers";
import Icon from "../components/Icon";
import {
  resetFilterState,
  updateActiveSources,
  updateFilterState,
} from "../redux/filterReducer";
import config from "../config/index";
import "./ArticleView.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveSources: bindActionCreators(updateActiveSources, dispatch),
    resetFilterState: bindActionCreators(resetFilterState, dispatch),
    updateFilterState: bindActionCreators(updateFilterState, dispatch),
  };
};

const mapStateToProps = (state) => ({
  activeSourcesFromReducer: state.filterReducer.activeSources,
});

const generateFilterColumns = (sources, queryString) => {
  const allFilters = {};
  // eslint-disable-next-line array-callback-return
  sources.map((source) => {
    const filterColumns = [];
    if (
      queryString !== undefined &&
      queryString !== null &&
      queryString.length > 1
    ) {
      const queries = queryString.substring(1).split("&");
      // eslint-disable-next-line array-callback-return
      queries.map((q) => {
        if (q.length) {
          const kvp = q.split("=");
          const dataIndex = kvp[0].split("__")[0];
          const filterProperty = kvp[0].split("__")[1];
          const key = kvp[1];
          const categoryKey = key.split(",");
          if (filterProperty === "tilgjengelig") {
            const filter = getFilterColumn(
              "vestkystparkelementer",
              filterProperty
            );
            filterColumns.push(filter);
          }
          // eslint-disable-next-line array-callback-return
          categoryKey.map((k) => {
            if (
              k === "uthavner" ||
              k === "aktiviteter" ||
              k === "fasiliteter" ||
              k === "overnatting"
            ) {
              const filter = getFilterColumn("vestkystparkelementer", k);
              filterColumns.push(filter);
            }
          });

          if (filterProperty === "sesong") {
            const filter = getFilterColumn(
              "vestkystparkelementer",
              filterProperty
            );
            filter.value = key;
            filterColumns.push(filter);
          }

          if (dataIndex === source.dataIndex) {
            const filter = getFilterColumn(source.dataIndex, filterProperty);
            if (filter.name === "distance") {
              filter.value = key * 1000;
            } else {
              filter.value = key
                .replace("_", " ")
                .split(",")
                .map((k) => +k);
            }
            filterColumns.push(filter);
          }
        }
      });
    }
    allFilters[source.dataIndex] = filterColumns;
  });
  return allFilters;
};

const getFilterColumn = (dataIndex, key) => {
  if (key) {
    const object = Object.assign(
      {},
      config.articleModuleFilters[dataIndex][key]
    );
    return object;
  }
  return undefined;
};

class ArticleView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articleLimit: 20,
    };
    this.onSourceSelect = this.onSourceSelect.bind(this);
    this.updateActiveSourcesFilter = this.updateActiveSourcesFilter.bind(this);
  }

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;

    if (viewWidth < 769) {
      return [0, 0, viewHeight / 3, 0];
    }
    return [0, viewWidth / 2, 0, 90];
  }

  componentDidMount() {
    this.lazyLoad = new LazyLoad({
      threshold: 500,
      container: window.innerWidth < 769 ? window : window, // document.getElementById('article-view--scrollPane'),
      elements_selector: ".articleList--entry img",
    });
    this.checkActiveSourcesFilter();

    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.updateActiveSourcesFilter();
    }
  }

  checkActiveSourcesFilter() {
    const { location } = this.props;
    if (location.pathname && location.pathname.length < 12) {
      this.pushActiveSources();
    } else {
      this.updateActiveSourcesFilter();
    }
  }

  pushActiveSources() {
    const baseUrl = "/alleturer/";
    const url = baseUrl + this.props.activeSourcesFromReducer;
    this.props.history.push(url);
  }

  updateActiveSourcesFilter() {
    const { location, updateActiveSources } = this.props;
    if (location.pathname && location.pathname.length > 11) {
      const activeSourcesFromUrl = location.pathname.substring(11);
      updateActiveSources(activeSourcesFromUrl);
    }
  }

  componentWillUnmount() {
    this.lazyLoad.destroy();
  }

  onSourceSelect(source) {
    const { history, location } = this.props;
    const sources = this.state.activeSources;
    let enable = false;
    if (sources.indexOf(source) === -1) {
      enable = true;
    }
    const filteredSources = sources.filter((s) => s !== source);

    if (enable) {
      filteredSources.push(source);
    }
    const baseUrl = "/alleturer";
    const query = location.search || "";
    history.push(baseUrl + "/" + filteredSources.join("+") + query);
  }

  onArticleOpen(article) {
    const { history } = this.props;
    const baseUrl = "/tur";

    history.push(baseUrl + "/" + article.id.replace("_", "/"));
    window.scroll(0, 0);
  }

  static getDerivedStateFromProps(props, state) {
    const filterColumns = generateFilterColumns(
      props.sources,
      props.location.search
    );
    state.filterColumns = filterColumns;

    let {
      match: {
        params: { activeSources },
      },
    } = props;
    state.activeSources = activeSources ? activeSources.split("+") : [];
    return state;
  }

  menuHandler = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { t } = this.props;
    const { filterColumns, activeSources } = this.state;
    const { open } = this.state;

    return (
      <>
        {open ? (
          <button
            className="menu-close-btn"
            onClick={this.menuHandler}
            aria-label="Close filters"
          >
            <Icon name="close" />
          </button>
        ) : (
          <button className="filter-btn" onClick={this.menuHandler}>
            <Icon name="filter" />
            {t("filter")}
          </button>
        )}
        <div
          className={
            open
              ? "article-view--container open"
              : "article-view--container closed"
          }
          id="scrollPane"
        >
          <MapPadding padding={this.calcMapPadding()} />
          <SlideMenu
            baseUrl={"/alleturer/"}
            activeSources={activeSources.join("+")}
            onSourceSelect={this.onSourceSelect}
          />
          <div className="article-view--content" id="article-view--scrollPane">
            <Articles
              hideSelector
              hideLayers
              filterColumns={filterColumns}
              activeSources={activeSources}
              onContentChange={() => this.lazyLoad.update()}
              onArticleOpen={this.onArticleOpen}
              articleComponent={GodTurArticleListComponent}
              thumbnailSize="small"
            />
          </div>
          <ArticleLayers
            activeSources={activeSources}
            filterColumns={filterColumns}
            onArticleOpen={this.props.onArticleOpen}
          />
        </div>
      </>
    );
  }
}

ArticleView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  updateActiveSources: PropTypes.func.isRequired,
  activeSourcesFromReducer: PropTypes.string,
  viewHeight: PropTypes.number.isRequired,
  viewWidth: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  onArticleOpen: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  provideViewSize(
    withRouter(
      translate("ArticleView")(
        provideMapState(ensureArticleModuleConfig(ArticleView))
      )
    )
  )
);
