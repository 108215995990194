/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { TranslatorProvider } from "react-translate";
import translations from "./translations/translations";

import {
  translations as reactArticleModuleTranslations,
  ArticleModule,
} from "@avinet/react-article-module";
import Router from "./router";
import MapWrapper from "./components/MapWrapper";
import "./App.scss";
import { withReducer } from "./hocs/withReducer";
import config from "./config/index";

class App extends React.Component {
  getTranslations(lang) {
    return Object.assign(
      translations[lang],
      reactArticleModuleTranslations[lang]
    );
  }

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <TranslatorProvider translations={this.getTranslations("no")}>
          <MapWrapper>
            <ArticleModuleComponent>
              <Router />
            </ArticleModuleComponent>
          </MapWrapper>
        </TranslatorProvider>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;

const ArticleModuleComponent = withReducer((props) => {
  return (
    <ArticleModule
      adaptiveUrl={config.adaptiveUrl}
      articleModuleUuid={config.articleModuleUuid}
      onSearchInit={(reducer) => props.injectReducer("searchSources", reducer)}
    >
      {props.children}
    </ArticleModule>
  );
});
